<template>
  <div class="category">
    <CardsList :loadingState="areCardsLoading" :cardsPerPage="perPage"/>
    <div v-if="isPossibleToGoBefore || isPossibleToGoAfter" class="vs-pagination-content vs-component--primary">
      <button
        class="vs-pagination__arrow prev"
        :disabled="(isPossibleToGoBefore) ? false : true"
        @click="goToPreviousPage"
        >
        <i class="vs-icon-arrow"></i>
      </button>
      <div class="vs-pagination__slot"> {{ page }} </div>
      <button 
        class="vs-pagination__arrow next"
        :disabled="(isPossibleToGoAfter) ? false : true"
        @click="goToNextPage"
        >
        <i class="vs-icon-arrow"></i>
      </button>
    </div>
  </div>
</template>

<script>
import CardsList from '@/components/CardsList.vue'
import { ViewsWithCardsList } from '@/mixins/ViewsWithCardsList.js'

export default {
  name: 'Category',
  mixins: [ViewsWithCardsList],
  computed: {
    categoryName() {
      return this.$route.params.categoryName
    }
  },
  watch: {
    categoryName() {
      this.areCardsLoading = true
      this.dispatchFetchCards()
    }
  },
  methods: {
    dispatchFetchCards(direction) {      
      return this.$store.dispatch('card/fetchAll', {
        category: this.categoryName,
        perPage: this.perPage,
        direction
      }).then(() => {
        this.areCardsLoading = false
        if (direction) {
          this.page = (direction === 'after') ? this.page + 1 : this.page - 1
        } else {
          this.page = 1
        }
      })
    }
  },
  components: {
    CardsList
  }
}
</script>
